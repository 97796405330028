@import './colors.css';

.margin_top_row {
    margin-top: 1rem;
}

.half_margin_top_row {
    margin-top: 0.5rem;
}

.add_activity_button {
    color: var(--primary--color);
    cursor: pointer;
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-right: 1rem;
    float: "right";
}

.edit_header_icon_save {
    cursor: pointer;
    margin-right: 15px;
    font-size: 1.5rem;
    color: var(--primary--color)
}

.custom_error_messages {
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.edit_header_icon_close {
    cursor: pointer;
    font-size: 1.5rem;
    color: grey
}

.custom_checkbox input[type=checkbox] {
    transform: scale(1.5);
}

.edit_header_icon_add {
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--primary--color)
}

.error_div {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.parent_info_row {
    margin-bottom: 1rem;
}

.active_switch .custom-control-label {
    cursor: pointer;
}


.dropdown_icon {
    font-size: 1.2rem;
    color: #6c757d;
    float: right;
}

.action_menu {
    width: 2rem;
    margin-right: 5px;
}

.permissionGroupAction {
    margin-right: 5px;
}

.error_email{
    border: 1px solid #dc3545;
    border-radius: .25rem
}